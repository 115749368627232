import { DEFAULT_LOCALE } from './keys';

export enum Paths {
  ForgotPassword = '/auth/forgot',
  ForgotPasswordReset = '/auth/forgot/reset',
  Home = '/',

  Promotion = '/promotion',
  Specials = '/promotion/specials',
  Tournaments = '/promotion/tournaments',
  TournamentDetails = '/tournaments/:id',

  MetaCasino = '/casino/:category?/:gameId?',
  Search = '/search',
  LobbyFavorite = '/casino/lobby/favorite',
  LobbyBetUTopPicks = '/casino/lobby/top-picks',
  LobbyRecently = '/casino/lobby/recently',
  LobbyNewRelease = '/casino/lobby/new-releases',
  LobbyProviders = '/casino/lobby/providers',
  LobbyProvidersGames = '/casino/providers/:producerId',
  MetaSport = '/:sportType?/:slug?/:eventId?',
  MetaLiveSport = '/:sportType?/live/:slug?',

  // used in both live and normal sportType
  DetailFixture = '/:sportType/:slug/:eventId',
  Invite = '/invite/:code',
  Dashboard = '/my',
  Wallet = '/my/wallet',
  Deposit = '/my/wallet/deposit',
  Deposit_Fiat = '/my/wallet/deposit/fiat',
  Deposit_Crypto = '/my/wallet/deposit/crypto',
  Deposit_Purchase = '/my/wallet/deposit/purchase',
  Withdraw = '/my/wallet/withdraw',
  TxHistory = '/my/wallet/tx',
  Setting = '/my/setting',
  MyCasinoBets = '/my/bets/casino',
  MySportBets = '/my/bets/sport',
  ChangePass = '/my/setting/change-password',
  ChangeEmail = '/my/setting/change-email',
  Enable2FA = '/my/setting/enable-2fa',
  Change2FA = '/my/setting/change-2fa',
  Verify = '/my/setting/verify',
  Affiliate = '/my/affiliate',
  AffiliateLanding = '/affiliates',
  Legals = '/legals/:tab?',
  PersonalDetails = '/my/setting/personal-details',
  VerifyEmail = '/my/setting/verify-email',
  ChangePhoneNumber = '/my/setting/change-phone-number',

  Blogs = '/blog',
  BlogDetails = '/blog/:slug',
  BlogCategory = '/blog/category/:tag',
}

type Href = string | { pathname: string };
interface Resolve<T extends Href> {
  locale: string;
  href: T;
  noPrefix?: boolean;
}

export const localizePathname = <T extends Href>(props: Resolve<T>) => {
  const { locale, href, noPrefix = false } = props;

  let pathname = typeof href === 'string' ? href : href.pathname;
  if (!pathname) return pathname;

  if (!noPrefix && locale && locale !== DEFAULT_LOCALE) {
    if (pathname === '/') {
      pathname = '';
    }

    pathname = `/${locale}${pathname}`;
  }

  return pathname;
};
