import { atom } from 'jotai';
import { focusAtom } from 'jotai-optics';
import _ from 'lodash';
import {
  INITIAL_USER_PREF,
  UserPrefer,
} from 'src/utils/constants/common-constant';
import { USER_PREF_KEY } from 'src/utils/constants/keys';
import { clientCookie } from 'src/utils/helpers/headers/headers.client';
import { ListBalanceUserOwnedItem } from 'src/utils/types/wallet-type';
import { stakeCoinAtom } from './auth';
import { balancesAtom, coinsAtom, pricesAtom } from './wallet';

export enum OddsAcceptance {
  NONE = 'none',
  ANY = 'any',
  HIGHER = 'higher',
}

const _loginAtom = atom(false);
const _registerAtom = atom(false);
export const promoCodeAtom = atom('');

export const loginAtom = atom(
  (get) => get(_loginAtom),
  (get, set, next?: boolean) => {
    set(_loginAtom, (prev) => next ?? !prev);
    set(_registerAtom, false);
  },
);

export const registerAtom = atom(
  (get) => get(_registerAtom),
  (get, set, next?: boolean, promoCode?: string) => {
    set(_registerAtom, (prev) => next ?? !prev);
    set(_loginAtom, false);

    if (promoCode) {
      set(promoCodeAtom, promoCode);
    }
  },
);
export const _userPrefAtom = atom(INITIAL_USER_PREF);

export const _userCountryAtom = atom<string | null>(null);

export const userCountryAtom = atom((get) => get(_userCountryAtom));

export const userPrefAtom = atom(
  (get) => get(_userPrefAtom),
  (get, set, next: Partial<UserPrefer>) => {
    const prev = get(_userPrefAtom);
    const payload: UserPrefer = { ...prev, ...next };

    // maxAge 30 day
    const maxAge = 30 * 24 * 60 * 60;

    clientCookie.set(USER_PREF_KEY, JSON.stringify(payload), { maxAge });

    set(_userPrefAtom, payload);
    return payload;
  },
);

export const oddsFormatAtom = focusAtom(userPrefAtom, (optic) =>
  optic.prop('oddsFormat'),
);

export const fiatAtom = atom(
  (get) => {
    const fiat = get(_userPrefAtom).fiat;
    const symbol = get(stakeCoinAtom);
    const coins = get(coinsAtom);
    const selectedCoin = _.find(coins, { symbol }) ?? null;

    return fiat && !selectedCoin?.isFiat;
  },
  (get, set, fiat: boolean) => {
    const prev = get(_userPrefAtom);
    const payload: UserPrefer = { ...prev, fiat };
    set(_userPrefAtom, payload);
  },
);
export const hideBalanceAtom = focusAtom(userPrefAtom, (optic) =>
  optic.prop('hideBalance'),
);

export const stakeCoinPriceAtom = atom((get) => {
  const symbol = get(stakeCoinAtom);
  const prices = get(pricesAtom);
  const rate = _.find(prices, { symbol });
  return rate?.price || '1';
});

export const stakeCoinBalanceAtom = atom((get) => {
  const symbol = get(stakeCoinAtom);
  const balances = get(balancesAtom);
  return (
    _.find(balances, { symbol }) ??
    ({
      balance: '0',
      balance_available: '0',
      balance_in_order: '0',
      decimal: 8,
      name: '',
      symbol: symbol,
      coin_id: -1,
      isFiat: false,
    } satisfies ListBalanceUserOwnedItem)
  );
});
