'use client';

/* eslint-disable lingui/no-unlocalized-strings */
import { backdropClasses } from '@mui/material';
import { dialogClasses } from '@mui/material/Dialog';
import { createTheme, Shadows } from '@mui/material/styles';
import React from 'react';
import ArrowDown from 'src/assets/icons/arrow-down.svg';
import CheckedIcon from 'src/assets/icons/checkbox-checked.svg';
import CheckIcon from 'src/assets/icons/checkbox.svg';
import { mainFont } from 'src/assets/styles/fonts';
import { isIOS } from 'src/utils/helpers/etc';

interface ThemeDesignColors {
  status: {
    green: React.CSSProperties['color'];
    red: React.CSSProperties['color'];
    gold: React.CSSProperties['color'];
    silver: React.CSSProperties['color'];
    copper: React.CSSProperties['color'];
  };
  border: {
    '1': React.CSSProperties['color'];
    '2': React.CSSProperties['color'];
  };
  header: React.CSSProperties['color'];

  bg: {
    '1': React.CSSProperties['color'];
    '2': React.CSSProperties['color'];
    '3': React.CSSProperties['color'];
  };
  content: {
    '1': React.CSSProperties['color'];
    '2': React.CSSProperties['color'];
    '3': React.CSSProperties['color'];
    '4': React.CSSProperties['color'];
    '5': React.CSSProperties['color'];
  };
}

declare module '@mui/material/styles' {
  interface Theme {
    custom: ThemeDesignColors;
  }
  interface DeprecatedThemeOptions {
    custom: ThemeDesignColors;
  }

  interface Palette {
    custom: ThemeDesignColors;
  }

  interface PaletteOptions {
    custom: ThemeDesignColors;
  }

  interface TypographyVariants {
    '16': React.CSSProperties;
    '18': React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    '16'?: React.CSSProperties;
    '18'?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    '16': true;
    '18': true;
  }
}

const defaultTheme = createTheme();

// const hover = {
//   yellow: '#191502',
//   white: '#191919',
// };
export const custom = {
  status: {
    red: '#DA4545',
    green: '#10A342',
    gold: '#EACB34',
    silver: '#A5A5A5',
    copper: '#E99A50',
  },
  header: '#011219',
  border: {
    '1': '#3E4760',
    '2': '#485472',
  },
  bg: {
    '1': '#1D222E',
    '2': '#272D3D',
    '3': '#31384D',
    '4': '#1F2126',
  },
  content: {
    '1': '#ffffff',
    '2': '#7F869A',
    '3': '#515662',
    '4': '#E4E4E4',
    '5': '#7D8BA0',
  },
  // hover,
};
const common = {
  black: '#1D222E',
  white: '#fff',
};

export const primary = {
  main: '#0BA8F8',
  dark: '#0A8DD0',
};

const themev5 = createTheme({
  // @ts-ignore
  custom,
  palette: {
    custom,
    mode: 'dark',
    background: {
      paper: custom.bg[2],
      default: common.black,
    },
    /**
     * createTheme only requires main color
     * and it will automatically generate dark
     * and contrastText color for you
     */
    primary,
    secondary: {
      main: '#10FF9B',
    },
    warning: { main: '#EA8A44' },
    success: { main: custom.status.green },
    error: { main: custom.status.red },
    common,
    divider: custom.border[1],
  },
  shape: {
    borderRadius: 3,
  },
  typography: {
    fontFamily: [mainFont.style.fontFamily].join(','),
    fontSize: 14,

    allVariants: { lineHeight: 1.25, fontWeight: 400 },
    '16': { fontSize: 16, fontWeight: 500, lineHeight: 1.25 },
    '18': { fontSize: 18, fontWeight: 700, lineHeight: 1.25 },
    h5: { fontSize: 32, lineHeight: 1.25 },
    h6: { fontSize: 24, lineHeight: 1.25 },
    body1: { fontSize: 14, lineHeight: 1.25 },
    body2: { fontSize: 14, lineHeight: 1.25 },
    caption: { fontSize: 12, lineHeight: 1.25 },
  },
  shadows: Array(25).fill('none') as Shadows,
  breakpoints: {
    values: {
      xs: 0,
      sm: 500,
      md: 768,
      lg: 1248, // content's width + padding left + padding + right
      xl: 1400,
    },
  },
  //https://github.com/mui/material-ui/issues/32286
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        endAdornment: {
          top: '50% !important',
          lineHeight: 0,
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          [`&:has(> div.${backdropClasses.root}[style*="opacity: 0"])`]: {
            pointerEvents: 'none',
          },
        },
      },
    },
    MuiSwitch: { defaultProps: { color: 'success' } },
    MuiLink: {
      defaultProps: { underline: 'hover' },
      styleOverrides: { underlineHover: { cursor: 'pointer' } },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          body: {
            ...mainFont.style,
            WebkitTextSizeAdjust: 'none', // only on ios
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            '&:hover': {
              backgroundColor: custom.bg[3],
            },
            backgroundColor: custom.bg[3],
          },
        },
        gutters: {
          padding: '12px 16px',
          fontSize: 14,
          fontWeight: 500,
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'primary',
        icon: React.createElement(CheckIcon),
        checkedIcon: React.createElement(CheckedIcon),
      },
      styleOverrides: {
        root: {
          marginTop: -9,
          marginBottom: -9,
        },
      },
    },
    MuiContainer: {
      defaultProps: { maxWidth: 'lg' },
      styleOverrides: {
        root: {
          paddingLeft: '8px !important',
          paddingRight: '8px !important',
          boxSizing: 'border-box',
          overflow: 'hidden',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        wrapper: {
          color: custom.content[2],
          fontSize: 12,
          px: 2,
          fontWeight: 'bold',
        },
      },
    },
    MuiDialog: {
      defaultProps: { maxWidth: 'sm' },
      styleOverrides: {
        ...(() => {
          const m = 8;
          const mx = m * 2;
          const maxWidth = `calc(100% - ${mx}px) !important`;

          const breakpoints = defaultTheme.breakpoints.values;
          const down = defaultTheme.breakpoints.down;

          return {
            paper: {
              margin: m,
              maxWidth,
            },

            paperWidthSm: {
              width: breakpoints.sm,
              maxWidth: `calc(${breakpoints.sm}px - ${mx}px)`,
              [`&.${dialogClasses.scrollBody}`]: {
                [down(breakpoints.sm)]: {
                  maxWidth,
                },
              },
            },
            paperWidthMd: {
              width: breakpoints.md,
              maxWidth: `calc(${breakpoints.md}px - ${mx}px)`,
              [`&.${dialogClasses.scrollBody}`]: {
                [down(breakpoints.md)]: {
                  maxWidth,
                },
              },
            },
            paperFullScreen: {
              margin: 0,
              width: '100%',
              maxWidth: '100% !important',
            },
          };
        })(),
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '40px 40px 24px 40px',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '8px 40px 40px',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        label: {
          fontWeight: 'bold',
        },
      },
    },
    MuiButton: {
      defaultProps: { variant: 'contained', color: 'primary', size: 'medium' },
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 700,
          minWidth: 'unset',
          borderRadius: '6px',
          boxSizing: 'border-box',
          paddingLeft: '11px',
          paddingRight: '11px',
          lineHeight: 5 / 4,
        },

        outlined: {
          borderColor: primary.main,
          background: custom.bg[4],
          color: custom.content[4],
        },

        contained: {
          '&.Mui-disabled': {
            backgroundColor: primary.main,
            color: common.black,
            opacity: 0.3,
          },
          '&:hover.Mui-disabled': {
            backgroundColor: `${primary.dark} !important`,
          },
        },

        containedPrimary: {
          '&:hover': {
            backgroundColor: primary.dark,
          },
          color: common.black,
        },
        // outlinedPrimary: {
        //   '&:hover': {
        //     backgroundColor: hover.yellow,
        //   },
        // },
        // text: {
        //   '&:hover': {
        //     backgroundColor: hover.white,
        //   },
        // },
        sizeLarge: {
          fontSize: 14,
          padding: `12px 16px`,
        },
        sizeMedium: {
          fontSize: 14,
          padding: `10px 16px`,
        },
        sizeSmall: {
          fontSize: 14,
          padding: `8px 16px`,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: custom.bg[1],
          border: `1px solid ${custom.border[1]}`,
          borderRadius: 4,
          boxSizing: 'border-box',

          'label + &': {
            marginTop: 8,
          },
          [`& fieldset`]: { border: 'none' },

          '&:hover': {
            borderColor: custom.content[1],
          },

          '&:focus': {
            borderColor: primary.main,
          },
          '&.Mui-error': {
            borderColor: custom.status.red,
          },
          '&.Mui-disabled': {
            color: `rgba(255, 255, 255, 0.8)`,
            pointerEvents: 'none',
          },
        },
        adornedStart: { paddingLeft: 12 },
        inputAdornedStart: { paddingLeft: 0 },
        adornedEnd: { paddingRight: 12 },
        inputAdornedEnd: { paddingRight: 0 },
        multiline: { padding: 0 },
        input: {
          padding: '12px 16px',
          height: 'auto',
          lineHeight: 1.25,
          '&::placeholder': {
            color: custom.content[3],
            opacity: 1,
          },
          '&[type=number]': {
            MozAppearance: 'textfield',
          },
          '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
          },
          '&[type=password], &[type=password]::-ms-reveal, &[type=password]::-ms-clear':
            {
              opacity: 0,
            },
          // currently, i just delay background-color changed when
          // autocomplete on chrome. it should be better.
          // god bless you
          '&:-webkit-autofill': {
            transitionDelay: '9999999s',
            transitionProperty: 'background-color, color',
          },
        },
      },
    },
    MuiToolbar: {
      defaultProps: { disableGutters: true },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: -9,
        },
      },
    },
    MuiFormLabel: {
      defaultProps: { error: false, focused: false },
      styleOverrides: {
        asterisk: {
          color: custom.status.red,
        },
        root: {
          color: common.white,
          fontSize: 14,
          fontWeight: 500,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: custom.bg[2],
          backgroundImage: 'none',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        gutterBottom: {
          marginBottom: '16px',
        },
      },
      defaultProps: {
        variantMapping: {
          h5: 'h1',
          h6: 'h2',
          body1: 'p',
          body2: 'p',
          '16': 'p',
          '18': 'p',
        },
      },
    },
    MuiPopover: {
      defaultProps: { disableScrollLock: !isIOS() },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: 14,
          marginLeft: 0,
          marginTop: 4,
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'outlined',
        IconComponent: ArrowDown,
      },
      styleOverrides: {
        select: {
          padding: '12px 40px 12px 16px',
          lineHeight: 1.25,
          height: 'auto',
        },
        outlined: {
          minHeight: 'unset',
        },
        icon: {
          transform: 'translateY(-50%) rotate(0deg)',
          transition: defaultTheme.transitions.create('transform'),
          color: 'inherit',
          top: '50%',
        },
        iconOpen: {
          transform: 'translateY(-50%) rotate(180deg)',
        },
      },
    },
    MuiTabs: {
      defaultProps: {
        selectionFollowsFocus: true,
      },
    },
    MuiTab: {
      styleOverrides: {
        root: { minWidth: 160, textTransform: 'none' },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        stickyHeader: {
          backgroundColor: custom.bg[2],
        },
        root: {
          borderBottom: `1px solid ${custom.border[1]}`,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:last-child td': {
            borderBottom: 0,
          },
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        enterTouchDelay: 0,
      },
      styleOverrides: {
        tooltip: {
          backgroundColor: custom.bg[2],
          color: custom.content[2],
          border: `1px solid ${custom.border[1]}`,
          padding: 8,
          fontSize: 12,
        },
      },
    },
  },
});
export const DOWN_500 = themev5.breakpoints.down(500);

export default themev5;
