export enum SportTypeEnum {
  Sport = 'sport',
  ESport = 'esport',
  Casino = 'casino',
}
export interface MySport {
  _id: string;
  sportId: string;
  logo?: string;
  logoName?: string;
  name: string;
  numberOfFixtureLive: number;
  numberOfFixtureUp: number;
  numberOfOutright: number;
  typePopular: number;
  slug: string;
  color?: string;
  languages: Record<string, { name: string; customName: string }>;

  __sportType: SportTypeEnum;
}

export interface MyTournament {
  _id: string;
  tournamentId: string;
  name: string;
  priorityInCategory: number;
  sportId: string;
  typePopular: number;
  languages: Record<string, { name: string; customName: string }>;

  __sportType: SportTypeEnum;
}

export interface IMarketFilter {
  id: string;
  _id: string;
  marketId: string;
  marketName: string;
  marketCustomName: string | null;
  priorityInSport: string;
  sportId: string;
  languages?: {
    [key: string]: {
      marketPopularName: string;
    };
  };
}
