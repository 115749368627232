import _ from 'lodash';
import { ErrorTypes } from 'src/components/modules/bet-slip-drawer/components/shared/error-message';
import { toCrypto } from 'src/hooks/use-stake-coin';
import {
  BaseEvent,
  DetailEventInterface,
  MarketInterface,
  SportEvent,
  TemplateInterface,
} from 'src/utils/types/event';
import { OutcomeActive } from 'src/utils/types/outcome';
import { BetRadarV5 } from 'src/utils/types/socket-type';
import {
  BetSlipItemInterface,
  BetSource,
  InactiveBetGameError,
  OddsChangeError,
} from 'src/utils/types/sportsbook';
import { BrMarketStatus, PRODUCER_WEIGHT } from '../constants/common-constant';
import { MIN_STAKE_IN_USD } from '../constants/keys';
import { BetSourceStatus } from '../types/admin-v2/sportsbook';
import { compareSpecifiers, excludeBetSource, parseOdds } from './fixture';

export function sleep(ms = 700) {
  return new Promise<void>((resolve) => {
    const token = setTimeout(() => {
      resolve();
      clearInterval(token);
    }, ms);
  });
}

export const timeout = <T>(fn: Promise<T>, ms: number, exception?: any) => {
  let timer: any;
  return Promise.race<T>([
    fn,
    new Promise<any>(
      (_r, rejected) => (timer = setTimeout(rejected, ms, exception)),
    ),
  ]).finally(() => clearTimeout(timer));
};

/**
 * Map sport event status from socket to API return type
 * @param fixture event
 * @param raw Sport event status from socket
 * @returns Compatible sport event status
 */
const mapEventStatus = (
  fixture: BaseEvent,
  raw?: BetRadarV5.OddChange['sport_event_status'],
): SportEvent.Root | undefined => {
  if (!raw) return fixture?.sportEventStatus;

  const periodScores = _.chain([_.get(raw, ['period_scores'])])
    .flatMap((o) => o?.period_score)
    .compact()
    .map((o) => {
      return {
        _home_score: o._home_score,
        _away_score: o._away_score,
        _match_status_code: o._match_status_code,
        _number: o._number,
      } as SportEvent.PeriodScore;
    })
    .value();

  const { sportEventStatus: event = {} } = fixture || {};
  const { clock } = event;

  const matchTime = _.get(raw, ['clock', '_match_time'], clock?._match_time);

  const remainingTime = _.get(
    raw,
    ['clock', '_remaining_time'],
    clock?._remaining_time,
  );
  const remainingTimeInPeriod = _.get(
    raw,
    ['clock', '_remaining_time_in_period'],
    clock?._remaining_time_in_period,
  );

  return {
    _match_status: _.get(raw, '_match_status', event?._match_status),
    period_scores: {
      period_score: periodScores,
    },
    clock: {
      _match_time: matchTime,
      _remaining_time: remainingTime,
      _remaining_time_in_period: remainingTimeInPeriod,
    },
    _match_time: matchTime,
    _remaining_time: remainingTime,
    _remaining_time_in_period: remainingTimeInPeriod,
    _home_score: _.get(raw, '_home_score', event?._home_score),
    _home_gamescore: _.get(raw, '_home_gamescore', event?._home_gamescore),
    _away_score: _.get(raw, '_away_score', event?._away_score),
    _away_gamescore: _.get(raw, '_away_gamescore', event?._away_gamescore),
  };
};

export const mergeEventWithOddsChange = (
  event: BaseEvent,
  msg: BetRadarV5.OddChange,
): BaseEvent => {
  const marketOutcomes = parseOdds(
    event?.mainPopularOutcomes?.marketOutcomes,
    msg,
  );
  const sportEventStatus = mapEventStatus(event, msg.sport_event_status);

  const status = _.get(msg, ['sport_event_status', '_status'], undefined);

  return {
    ...event,
    ...(status && { status: +status }),
    mainPopularOutcomes: {
      ...event.mainPopularOutcomes,
      marketOutcomes,
    },
    sportEventStatus,
  };
};

export const generateOutcomeKey = ({
  marketId,
  specifiers,
  outcomeId,
}: {
  marketId?: string;
  specifiers?: string | null;
  outcomeId?: string;
}) => {
  return `${marketId || ''}-${specifiers || ''}-${outcomeId || ''}`;
};
export const getOutcomesFromMessage = (message: BetRadarV5.BetSettlement) => {
  const marketData = _.flatten([message.outcomes.market]);
  let outcomes: { [key: string]: boolean } = {};

  for (const m of marketData) {
    for (const o of m.outcome || []) {
      const key = generateOutcomeKey({
        marketId: m._id,
        specifiers: m._specifiers,
        outcomeId: o._id,
      });

      outcomes[key] = true;
    }
  }

  return outcomes;
};
export const updateEventWithBetSettlement = (
  event: BaseEvent,
  message: BetRadarV5.BetSettlement,
) => {
  const outcomes = getOutcomesFromMessage(message);

  return {
    ...event,
    mainPopularOutcomes: {
      ...event.mainPopularOutcomes,
      marketOutcomes: _.map(event?.mainPopularOutcomes?.marketOutcomes, (o) => {
        const outcomeKey = generateOutcomeKey({
          marketId: o?.marketId,
          specifiers: o?.marketSpecifiers,
          outcomeId: o?.outcomeId,
        });

        const isValid =
          o && o.producerId == message._product && outcomes[outcomeKey];
        return isValid
          ? {
              ...o,
              marketStatus: BrMarketStatus.STOPPED,
              outcomeActive: OutcomeActive.INACTIVE,
            }
          : o;
      }),
    },
  };
};

export const mergeOutrightWithOddsChange = (
  outright: BaseEvent,
  message: BetRadarV5.OddChange,
): Promise<BaseEvent> =>
  new Promise((resolve) => {
    const newOdds = parseOdds(
      outright.mainPopularOutcomes.marketOutcomes,
      message,
    );

    resolve({
      ...outright,
      mainPopularOutcomes: {
        ...outright.mainPopularOutcomes,
        marketOutcomes: newOdds,
      },
    });
  });

export const mergeDetailFixtureWithOddsChange = async (
  event: DetailEventInterface,
  msg: BetRadarV5.OddChange,
): Promise<DetailEventInterface> => {
  // convert to array
  const markets = _.chain([msg?.odds])
    .flatMap((o) => o?.market)
    .filter((x) =>
      x ? !!event?.templates?.find((o) => o.marketId === x._id) : false,
    )
    .value() as BetRadarV5.BrMarket[];

  const _product = +msg._product;
  const nextWeight = _.get(PRODUCER_WEIGHT, [msg._product]);

  const status = msg?.sport_event_status?._status || event.status;

  const obj: DetailEventInterface = {
    ...event,
    sportEventStatus: mapEventStatus(event, msg?.sport_event_status),
    status: +status,
    templates: event?.templates?.map?.((template) => {
      let tActive = OutcomeActive.INACTIVE; // inactive

      // const marketSpecifier = _.chain(template.markets)
      //   .first()
      //   .get('outcomeGroups')
      //   .first()
      //   .get('outcomes')
      //   .first()
      //   .get('marketSpecifiers')
      //   .value();

      // const thisMarketHasSpecifier = Boolean(marketSpecifier);

      // because there are many market with same id in msg, we have to use `filter()`
      const matchedMarkets = _.filter(markets, (_market) => {
        const equalMarketId = _market._id === template.marketId;

        // if (thisMarketHasSpecifier) {
        //   return (
        //     equalMarketId &&
        //     compareSpecifiers(_market._specifiers ?? '', marketSpecifier)
        //   );
        // }
        return equalMarketId;
      });
      // if not found market in message, we continue to the next one
      if (_.isEmpty(matchedMarkets)) return template;

      const _template: TemplateInterface = {
        ...template,
        markets: template.markets.map((market) => {
          // filter market co tat ca cac outcome inActive

          let mActive = OutcomeActive.INACTIVE;

          const _market: MarketInterface = {
            ...market,
            outcomeGroups: market.outcomeGroups.map((group) => ({
              ...group,
              outcomes: _.map(group.outcomes, (outcome) => {
                // lưu lại val cũ của market status đề phòng
                // ko tìm thấy market theo specifier and market_id
                // bởi vì socket ko bắn đủ _markets
                const prevMarketStatus = outcome.marketStatus;

                // 2/1/2024 - god blesses you
                // we have to remove this line
                // we just want to have a nice Tet holiday
                // so...
                _.set(outcome, ['marketStatus'], BrMarketStatus.ACTIVE);

                const _producerId = outcome.producerId;
                // inactive all outcome having producerId not match to msg._product
                if (+_product !== +_producerId) {
                  _.set(outcome, ['outcomeActive'], OutcomeActive.INACTIVE);
                }

                const currentWeight = _.get(PRODUCER_WEIGHT, _producerId);
                // LIVE gonna LDO or UPCOMING gonna LIVE
                // heaviest weight will be popped up
                if (nextWeight > currentWeight) {
                  _.set(outcome, ['producerId'], _product);
                  _.set(outcome, ['_id'], null);
                }

                // if current is LDO, ignore LIVE
                // if current is LIVE, ignore UPCOMING
                // just in case the messages are sent not in order
                if (currentWeight > nextWeight) {
                  return {
                    ...outcome,
                    outcomeActive: OutcomeActive.INACTIVE,
                  };
                }

                const targetMarket = _.find(matchedMarkets, (_market) =>
                  compareSpecifiers(
                    _market?._specifiers,
                    outcome.marketSpecifiers,
                  ),
                );

                if (!targetMarket) {
                  return { ...outcome, marketStatus: prevMarketStatus };
                }

                if (
                  targetMarket._status !== BrMarketStatus.ACTIVE &&
                  targetMarket._status !== BrMarketStatus.SUSPENDED
                ) {
                  return {
                    ...outcome,
                    outcomeActive: OutcomeActive.INACTIVE,
                  };
                }

                const targetOutcome = _.find(targetMarket?.outcome, {
                  _id: outcome.outcomeId,
                });

                if (!targetOutcome)
                  return { ...outcome, marketStatus: targetMarket._status };

                let active = +targetOutcome._active
                  ? OutcomeActive.ACTIVE
                  : OutcomeActive.INACTIVE;

                if (_.isUndefined(targetOutcome._odds)) {
                  active = OutcomeActive.INACTIVE;
                }

                if (active === OutcomeActive.ACTIVE) {
                  tActive = OutcomeActive.ACTIVE;
                  mActive = OutcomeActive.ACTIVE;
                }

                if (
                  targetMarket._status === BrMarketStatus.SUSPENDED &&
                  targetOutcome._active === OutcomeActive.ACTIVE
                ) {
                  return {
                    ...outcome,
                    marketStatus: BrMarketStatus.SUSPENDED,
                    outcomeActive: active,
                  };
                }

                return {
                  ...outcome,
                  outcomeActive: active,
                  currentOdd: targetOutcome?._odds ?? outcome.currentOdd,
                };
              }),
            })),
          };

          return { ..._market, active: +mActive };
        }),
      };

      return { ..._template, active: +tActive };
    }),
  };

  return obj;
};

export const mergeBetSlipWithOddsChange = (
  draft: BetSlipItemInterface,
  msg: BetRadarV5.OddChange,
): BetSlipItemInterface => {
  const { marketId, marketSpecifiers: specifiers, outcomeId } = draft;
  const producerId = +msg._product;

  let newDraft: BetSlipItemInterface = { ...draft };
  if (producerId != draft.producerId) {
    newDraft.producerId = producerId;
    newDraft._id = null;
  }

  const matchedMarket = _.chain([msg?.odds])
    .flatMap((o) => o?.market)
    .compact()
    .find(
      (market) =>
        market._id === marketId &&
        compareSpecifiers(market._specifiers ?? null, specifiers),
    )
    .value();

  if (!matchedMarket) {
    return newDraft;
  }

  if (
    [BrMarketStatus.SUSPENDED, BrMarketStatus.STOPPED].includes(
      matchedMarket._status ?? draft.marketStatus,
    )
  ) {
    return {
      ...newDraft,
      canceledError: { code: ErrorTypes.Suspended },
    };
  }

  if (matchedMarket._status === BrMarketStatus.SETTLED) {
    return {
      ...newDraft,
      canceledError: { code: ErrorTypes.Settled },
    };
  }
  if (matchedMarket._status != BrMarketStatus.ACTIVE) {
    return { ...newDraft, canceledError: true };
  }

  const matchedOutcome = _.find(matchedMarket.outcome, { _id: outcomeId });
  if (!matchedOutcome) {
    return newDraft;
  }

  return {
    ...newDraft,
    currentOdd: matchedOutcome._odds || newDraft.currentOdd,
    ...(+matchedOutcome._active != +OutcomeActive.ACTIVE && {
      canceledError: true,
    }),
  };
};

export const updateOddsChangeBetSlipError = (
  data: BetSlipItemInterface[],
  msg: OddsChangeError[],
): Promise<BetSlipItemInterface[]> =>
  new Promise((resolve) => {
    const updated = _.map(data, (item) => {
      const matched = _.find(
        msg,
        (o) => (o.bet_game_id ?? o.betGameId) === item._id,
      );

      if (!matched) return item;

      return {
        ...item,
        currentOdd: String(matched.odds),
      };
    });

    resolve(updated);
  });

export const updateInvalidBetSlipError = (
  data: BetSlipItemInterface[],
  error: InactiveBetGameError | InactiveBetGameError[],
  opts: { fiat: boolean; price: string },
): Promise<BetSlipItemInterface[]> =>
  new Promise((resolve) => {
    const errors = _.flatten([error]);

    const updated = _.map(data, (item): BetSlipItemInterface => {
      const matched = _.find(errors, (o) => o.betGameId === item._id);

      if (!matched) return item;

      if (item.source !== BetSource.CAMPAIGN) {
        if (String(matched.code) === '366') {
          return {
            ...item,
            canceledError: { code: ErrorTypes.Suspended },
          };
        }

        if (String(matched.code) === '367') {
          return {
            ...item,
            canceledError: { code: ErrorTypes.Settled },
          };
        }

        return { ...item, canceledError: true };
      }

      switch (String(matched.code)) {
        case '52':
          return {
            ...item,
            singleStakeError: {
              code: ErrorTypes.MinStake,
              params: { amount: MIN_STAKE_IN_USD },
            },
          };

        case '350':
        case '351':
          return { ...item, sameFixtureError: true };

        case '352':
          return {
            ...item,
            canceledError: { code: ErrorTypes.Campaign___Blacklist },
          };

        case '353':
          return {
            ...item,
            singleStakeError: { code: ErrorTypes.Campaign___ExceedExpense },
          };

        case '355':
          return {
            ...item,
            canceledError: { code: ErrorTypes.Campaign___Suspended },
          };

        case '357':
          return {
            ...item,
            canceledError: { code: ErrorTypes.Campaign___Ended },
          };

        case '358': {
          const usd = matched.minStake ?? item.singleStakeRange?.min;

          return {
            ...item,
            singleStakeRange: {
              ...item.singleStakeRange,
              min: usd,
            },
            singleStakeError: {
              code: ErrorTypes.MinStake,
              params: {
                amount: opts.fiat ? usd : +toCrypto(usd, opts.price),
              },
            },
          };
        }

        case '361': {
          const usd = matched.maxStake ?? item.singleStakeRange?.max;

          return {
            ...item,
            singleStakeRange: {
              ...item.singleStakeRange,
              max: usd,
            },
            singleStakeError: {
              code: ErrorTypes.MaxStake,
              params: { amount: opts.fiat ? usd : +toCrypto(usd, opts.price) },
            },
          };
        }

        case '362': {
          return {
            ...item,
            canceledError: { code: ErrorTypes.Campaign___Limited },
          };
        }

        default:
          return {
            ...item,
            singleStakeError: { code: matched.code },
          };
      }
    });
    resolve(updated);
  });

export const validateBetSlips = (
  items: BetSlipItemInterface[],
  minStake: string | number,
): Promise<any> =>
  new Promise<any>((resolve, reject) => {
    let hasError = false;

    const checked = _.map(items, (o) => {
      if (!o.singleStake) {
        hasError = true;

        return {
          ...o,
          singleStakeError: {
            code: ErrorTypes.MinStake,
            params: { amount: o.singleStakeRange?.min ?? minStake },
          },
        };
      }
      return o;
    });

    if (hasError) {
      return reject({ code: 'required', data: checked });
    }
    return resolve(true);
  });

export const stopBetSlips = (
  betSlips: BetSlipItemInterface[],
  sources: BetSourceStatus[],
) => {
  return _.map(betSlips, (bs) => {
    const output = { ...bs };

    for (const src of sources) {
      const { producer, subscribed, betSource } = src;
      if (excludeBetSource(betSource, producer)) {
        continue;
      }

      if (
        subscribed == 0 &&
        !output.canceledError &&
        +output.producerId === +producer &&
        output.source === betSource
      ) {
        output.canceledError = true;
      }
    }

    return output;
  });
};
